<template>
  <div id="resident-store-shipping" class="data-list-container">
    <div class="vx-row page-row">
      <div class="vx-col left-col">

        <BackButton
          label="Retour au panier"
          @click="onBackBtnClick"
        />
        <ResidentHeader
          page-title="Livraison"
          :displayResidentMenu="false"
          class="mt-sm-0"
        />

        <div class="delivery-header">
          <img class="delivery-icon" src="@/assets/images/ehpad/delivery-black.svg"/>
          <div class="delivery-content">
            <div class="delivery-title">
              Choix de la livraison
            </div>
            <div class="delivery-legend">
              Pour toutes vos commandes "une seule fois", merci de sélectionner la méthode de livraison souhaitée.
            </div>
          </div>
        </div>
        <div class="shipping-detail">
          <div class="detail-header">
            <div class="header-title">Commandé une seule fois</div>
            <div class="header-price">
                <GrandTotal
                    :total="this.getTotalOnce() + this.getTotalShippingOnce()"
                    class="grand-total"
                />
            </div>
          </div>
          <div v-if="hasOnceProduct()">
            <div
                v-for="(sales, salesIndex) in getOnceProducts()"
                :key="salesIndex"
                class="order-item"
            >
              <div class="detail-content">
                <div class="order-vendor">
                  <div class="vendor-name">{{ sales.shopName }}</div>
                </div>
                <div class="order-items">
                  <div
                    v-for="(productSale, productSalesIndex) in sales.offers"
                    :key="productSalesIndex"
                    class="order-item"
                  >
                    <div class="item-photo">
                      <img :src="getProductImage(productSale)" class="item-image"/>
                    </div>
                    <div class="item-informations">
                      <div class="item-title">{{getProductsLabelByOfferId(productSale.offerId) }}</div>
                    </div>
                    <div class="item-quantity">x{{ productSale.lineQuantity }}</div>
                    <div class="item-price">{{ productSale.linePrice }}€</div>
                  </div>
                </div>
                <div class="shipping-label">Mode de livraison :</div>
                <ShippingModeSelector
                  :data="sales.offers"
                  :date="getDateDeliveryByOfferId(sales.offers[0].offerId)"
                  :loading="loading"
                  :modes="sales.shippingTypes"
                  :selected="sales.selectedShippingType"
                  class="shipping-selector"
                  @change="onShippingModeChange"
                />
              </div>
            </div>
          </div>
          <div v-if="hasOnceService()">
            <div
              v-for="(service, serviceIndex) in getOnceServices()"
              :key="serviceIndex"
              class="order-item"
            >
              <div class="detail-content">
                <div class="order-vendor">
                  <div class="vendor-name">{{ service.shop.name }}</div>
                </div>
                <div class="order-items">
                  <div class="order-item">
                    <div class="item-photo">
                      <img :src="getServiceImage(service)" class="item-image"/>
                    </div>
                    <div class="item-informations">
                      <div class="item-title">{{ service.service.model.label }}</div>
                    </div>
                    <div class="item-quantity">x{{ service.originalQuantity }}</div>
                    <div class="item-price">{{ service.price.totalAmount }}€</div>
                  </div>
                  <div class="order-item">
                    <div class="item-informations-delivery">Le prestataire prendra directement contact avec l'EHPAD afin
                      d'organiser le rendez-vous
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isDraftHasProduct('subscription')" class="shipping-detail subscription">
          <div class="detail-header">
            <div class="header-title">Ajouts d'abonnements</div>
            <div class="header-price">
              <!-- <p>{{ amounts.subscription }}€</p>
              <p v-if="nextOrderEstimatedAmount" class="next-order-amount">Soit un total pour la pour la commande du 1er {{nextMonth}} de {{ nextOrderEstimatedAmount }}€ (hors frais de livraison éventuels)</p>
              <p v-else class="next-order-amount">Votre première commande (1er {{nextMonth}}) sera donc de {{ amounts.subscription }}€ (hors frais de livraison éventuels)</p> -->
            </div>
          </div>
          <div v-if="isDraftHasProduct('subscription','product')" class="detail-content">
            <div class="order-category">Produits</div>
            <div class="order-items">
              <div
                  v-for="(product, productIndex) in getProducts('subscription', 'product')"
                  :key="productIndex"
                  class="order-item"
              >
                <div class="item-photo">
                  <img :src="product.photo" class="item-image"/>
                </div>
                <div class="item-informations">
                  <div class="item-title">{{ product.title }}</div>
                  <div class="item-description" v-html="product.description"></div>
                  <div class="item-legend" v-html="product.legend"></div>
                </div>
                <div class="item-actions">
                  <div class="item-quantity">x{{ product.quantity }}</div>
                  <div class="item-price">
                    {{ product.price }}€<br/>
                    <div class="item-frequency">{{ formatFrequency(product.frequency) }}</div>
                  </div>
                </div>
              </div>
              <div class="order-item">
                <div class="item-informations-delivery">Première livraison prévue entre le <strong>5 et le 15
                  {{ nextMonth }}</strong><br/>Le montant exact des frais de livraison sera disponible dans votre menu
                  "Abonnement", en fonction des produits qui feront partie de chaque commande mensuelle.
                </div>
              </div>
            </div>
          </div>
          <div v-if="isDraftHasProduct('subscription','service')" class="detail-content">
            <div class="order-category">Services</div>
            <div class="order-items">
              <div
                  v-for="(service, serviceIndex) in getProducts('subscription', 'service')"
                  :key="serviceIndex"
                  class="order-item"
              >
                <div class="item-photo">
                  <img :src="service.photo" class="item-image"/>
                </div>
                <div class="item-informations">
                  <div class="item-title">{{ service.title }}</div>
                  <div class="item-description" v-html="getServiceOptions(service)"></div>
                  <div class="item-legend" v-html="service.legend"></div>
                </div>
                <div class="item-actions">
                  <div class="item-quantity">x{{ service.quantity }}</div>
                  <div class="item-price">
                    {{ service.price }}€<br/>
                    <div class="item-frequency">{{ formatFrequency(service.frequency) }}</div>
                  </div>
                </div>
              </div>
              <div class="order-item">
                <div class="item-informations-delivery"> {{
                    getProducts('subscription', 'service').length > 1 ? 'Le(s) prestataire(s) prendront' : 'Le prestataire prendra'
                  }} directement contact avec l'EHPAD afin d'organiser les rendez-vous.<br/>
                  {{
                    getProducts('subscription', 'service').length > 1 ? 'Les premiers rendez-vous auront' : 'Le premier rendez-vous aura'
                  }}
                  lieu entre le <strong>2 et le {{ nextMonthLastDay }}</strong></div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="vx-col right-col">
        <BasketSummary
          v-if="loaded"
          class="basket-summary"
          :toasted="isBasketSummaryToasted"
          :once-amount="amounts.once"
          :once-quantity="getProductCount('once')"
          :once-total="amounts.once + getTotalShippingOnce()"
          :shipping-address="institution"
          :shipping-fees="getTotalShippingOnce()"
          :shipping-name="getResidentName()"
          :subscription-amount="amounts.subscription"
          :subscription-amount-by-frequency="subscriptionAmountByFrequency"
          :subscription-quantity="getProductCount('subscription')"
          nextLabel="Passer au paiement"
          show-shipping-info
          show-total
          @next="onNextBtnClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import Routes from '@/constants/routes'
import Formats from '@/constants/formats'

import {getFrequencyLabel} from '@/modules/labels'
import {getNextMonth} from '@/modules/date'
import {
  computeBasketShipping,
  computeBasketTotal,
  getServiceOptionsAsHtml
} from '@/modules/services'

import BackButton from '@/components/BackButton'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import ShippingModeSelector from '@/components/selectors/ShippingModeSelector'
import BasketSummary from '@/components/BasketSummary'
import GrandTotal from '@/components/GrandTotal'

export default {
  name: 'StoreShipping',
  props: [
    'resident_id'
  ],
  components: {
    BackButton,
    ResidentHeader,
    ShippingModeSelector,
    BasketSummary,
    GrandTotal
  },
  data() {
    return {
      loaded: false,
      currentBasket: [],
      loading: false,
      amounts: {
        once: 0,
        subscription: 0,
        all: 0
      }
    }
  },
  computed: {
    institutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    userId() {
      return this.$store.getters['dataUser/getUserId']()
    },
    institution() {
      const institutionId = this.getInstitutionId()
      return this.$store.getters['dataInstitutions/getInstitutionById'](institutionId)
    },
    currentResident() {
      return this.$store.getters['dataResidents/getResidentById'](this.resident_id)
    },
    nextMonth() {
      return getNextMonth()
    },
    nextMonthLastDay() {
      moment.locale('fr')
      return moment().add(1, 'M').endOf('month').format(Formats.DisplayDateText)
    },
    subscriptionAmountByFrequency() {
      return this.$store.getters['dataDrafts/getSubscriptionAmountByFrequency'](this.getUserId(), this.resident_id)
    },
    isBasketSummaryToasted () {
      return this.$store.state.windowWidth < 768
    }
  },
  methods: {
    onBackBtnClick(e) {
      this.$router.push({
        name: Routes.ResidentStoreBasket,
        params: {
          resident_id: this.resident_id
        }
      })
    },
    getDraftAmount(draftType, productType) {
      return this.$store.getters['dataDrafts/getDraftAmount'](this.getUserId(), this.resident_id, draftType, productType)
    },
    getUserId() {
      return this.$store.getters['dataUser/getUserId']()
    },
    getInstitutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    async onNextBtnClick() {
      const draft_once = this.getCurrentDraft('once');
      draft_once.institutionId = this.getInstitutionId()
      let currentBasket = this.currentBasket
      const onceProducts = this.getOnceProducts()
      if (onceProducts) {
        onceProducts.forEach(sales => {
          sales.offers.forEach((item) => {
            var productsInBasket = draft_once.products.filter(p => p.type === 'product')
            let key = this.getBasketOfferById(productsInBasket, item.offerId)
            if (!productsInBasket[key].shippingTypeCode) {
              productsInBasket[key].shippingTypeCode = sales.selectedShippingType.code
              if( sales.selectedShippingType.code === 'wished-date' && !productsInBasket[key].deliveryDate) {
                productsInBasket[key].deliveryDate = this.getNextDateDelivery(sales.selectedShippingType.deliveryTime.earliestDays)
              }
            } else {
              if( sales.selectedShippingType.code === 'wished-date' && !productsInBasket[key].deliveryDate) {
                productsInBasket[key].deliveryDate = this.getNextDateDelivery(sales.selectedShippingType.deliveryTime.earliestDays)
              }
            }
          })
        })
        const resident_id = this.resident_id
        await this.$store.dispatch('dataMultiBasket/updateBasket', {
          draft_once, currentBasket, resident_id, residentName: this.getResidentName()
        })
      }
      this.$router.push({
        name: Routes.ResidentStorePayment,
        params: {
          resident_id: this.resident_id
        }
      })
    },
    getNextDateDelivery(earliestDay) {
      let date = new Date();
      date.setHours(23)
      date.setDate(date.getDate() + earliestDay).toLocaleString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
      return  {
        earliest: moment(date).utc().set({hour:21,minute:59,second:59,millisecond:59}).format(),
        latest: moment(date).utc().set({hour:21,minute:59,second:59,millisecond:59}).format()
      }
    },
    onShippingModeChange(mode, data, date) {
      if (this.loading === false) {
        this.loading = true
        this.$vs.loading()
        const draft_once = this.getCurrentDraft('once');
        draft_once.institutionId = this.getInstitutionId()
        let elementInBasket = this.currentBasket.quoteContent.quoteContentProduct.orders.findIndex((product) => {
          return product.offers.find((offers) => {
            return offers.offerId === data[0].offerId;
          })
        })
        this.currentBasket.quoteContent.quoteContentProduct.orders[elementInBasket].selectedShippingType.code = mode
        let key = this.getBasketOfferById(draft_once.products, data[0].offerId)
        draft_once.products[key].shippingTypeCode = mode
        if(mode === 'wished-date' && date !== null) {
          draft_once.products[key].deliveryDate = {
            earliest: moment(date).utc().set({hour:21,minute:59,second:59,millisecond:59}).format(),
            latest: moment(date).utc().set({hour:21,minute:59,second:59,millisecond:59}).format()
          }
        }
        let currentBasket = this.currentBasket
        const resident_id = this.resident_id
        this.$store.dispatch('dataMultiBasket/updateBasket', {
          draft_once, currentBasket, resident_id, residentName: this.getResidentName()
        }).then(() => {
              this.$store.commit('dataDrafts/UPDATE_BASKET_ORDER', {
                draft: this.getCurrentDraft('once'),
                basket: this.currentBasket
              })
              this.currentBasket = this.getCurrentMultiBasketByResidentId()
              this.loading = false
              this.$vs.loading.close()
            }
        )
      }
    },
    getResidentName() {
      return this.$store.getters['dataResidents/getResidentName'](this.resident_id)
    },
    getCurrentDraft(draftType) {
      return this.getFirstDraft(this.getUserId(), this.resident_id, draftType)
    },
    getFirstDraft(userId, residentId, draftType) {
      return this.$store.getters['dataDrafts/getFirstDraft'](userId, residentId, draftType)
    },
    getResidentById(residentId) {
      return this.$store.getters['dataResidents/getResidentById'](residentId)
    },
    getCurrentMultiBasketByResidentId() {
      return this.$store.getters['dataMultiBasket/getBasketByResidentId'](this.resident_id)
    },
    getServiceOptions(service) {
      return getServiceOptionsAsHtml(service)
    },
    updateAmounts() {
      this.amounts.all = (parseFloat(this.getDraftAmount('subscription')) + parseFloat(this.getTotalOnce()) + parseFloat(this.getTotalShippingOnce())).toFixed(2)
      this.amounts.once = this.getTotalOnce()
      this.amounts.subscription = this.getDraftAmount('subscription')
      this.loaded = true
      this.$vs.loading.close()
    },
    isDraftHasProduct(draftType, productType) {
      return this.getProductCount(draftType, productType) > 0
    },
    getProducts(draftType, productType) {
      return this.$store.getters['dataDrafts/getProducts'](this.getUserId(), this.resident_id, draftType, productType)
    },
    getBasketOfferById(listOffer, id) {
      return listOffer.findIndex((product) => {
        return product.meta.offer.offerId === id
      })
    },
    getTotalShipping(listTypeShipping, selectedShipping) {
      let mode = listTypeShipping.find((shipping) => {
        return shipping.code === selectedShipping.code
      })
      if (!mode || mode.totalShippingPrice === 0) {
        return 'Gratuit'
      }
      return mode.totalShippingPrice + '€'
    },
    getProductsByOfferId(offerId) {
      const product = _.find(this.currentBasket.basketLineProducts, (product) => {
        if (product.offerId === offerId) {
          return product
        }
      })

      return product
    },
    getProductsLabelByOfferId(offerId) {
      const product = _.find(this.currentBasket.basketLineProducts, (product) => {
        if (product.offerId === offerId) {
          return product
        }
      })

      return product.label
    },
    getDateDeliveryByOfferId(offerId) {
      const product = _.find(this.currentBasket.basketLineProducts, (product) => {
        if (product.offerId === offerId) {
          return product
        }
      })
      if( product.deliveryDate &&  product.deliveryDate.earliest) {
        return product.deliveryDate.earliest
      } else {
        return null
      }
    },
    getProductCount(draftType, productType) {
      return this.$store.getters['dataDrafts/getProductCount'](this.getUserId(), this.resident_id, draftType, productType)
    },
    getOnceProducts() {
      if (this.currentBasket && this.currentBasket && this.currentBasket.quoteContent && this.currentBasket.quoteContent.quoteContentProduct && this.currentBasket.quoteContent.quoteContentProduct.orders) {
        return _.orderBy(this.currentBasket.quoteContent.quoteContentProduct.orders, function(e) {
          return parseInt(e.offers[0].offerId);
        }, ['asc']);
      }
    },
    hasOnceProduct() {
      if (this.currentBasket && this.currentBasket && this.currentBasket.quoteContent && this.currentBasket.quoteContent.quoteContentProduct && this.currentBasket.quoteContent.quoteContentProduct.orders) {
        return true
      } else {
        return false
      }
    },
    getOnceServices() {
      if (this.currentBasket && this.currentBasket && this.currentBasket.quoteContent && this.currentBasket.quoteContent.quoteContentService && this.currentBasket.quoteContent.quoteContentService.orders) {
        return this.currentBasket.quoteContent.quoteContentService.orders
      }
    },
    hasOnceService() {
      if (this.currentBasket && this.currentBasket && this.currentBasket.quoteContent && this.currentBasket.quoteContent.quoteContentService && this.currentBasket.quoteContent.quoteContentService.orders) {
        return true
      } else {
        return false
      }
    },
    getTotalOnce() {
      return computeBasketTotal(this.currentBasket)
    },
    getTotalShippingOnce() {
      return computeBasketShipping(this.currentBasket)
    },
    formatFrequency(frequency) {
      return getFrequencyLabel(frequency)
    },
    getProductImage(productSale) {
      const draftOnce = this.getCurrentDraft('once');
      const product = draftOnce.products.find(p => p.type === 'product' && p.meta.offer.offerId === productSale.offerId)
      return product ? product.photo : null
    },
    getServiceImage(service) {
      const draftOnce = this.getCurrentDraft('once');
      const product = draftOnce.products.find(p => p.type === 'service' && p.meta.service.code === service.service.code)
      return product && product.meta.serviceModel.publicData ? product.meta.serviceModel.publicData.imageUrl : null
    }
  }
  ,
  async mounted() {
    this.$vs.loading.close()
    this.$vs.loading()
    if (!this.$store.state.dataInstitutions.isRegistered) {
      this.$store.dispatch('dataInstitutions/getInstitutions')
    }
    const draft_once = await this.getCurrentDraft('once');
    if (draft_once) {
      draft_once.institutionId = this.getInstitutionId()
      const resident_id = this.resident_id
      const currentBasket = await this.getCurrentMultiBasketByResidentId()
      if (!currentBasket) {
        await this.$store.dispatch('dataMultiBasket/setBasket', {
          draft_once, resident_id, residentName: this.getResidentName()
        })
      } else {
        await this.$store.dispatch('dataMultiBasket/updateBasket', {
          draft_once, currentBasket, resident_id, residentName: this.getResidentName()
        })
      }
      this.currentBasket = await this.getCurrentMultiBasketByResidentId()
      this.$store.commit('dataDrafts/UPDATE_BASKET_ORDER', {
        draft: draft_once,
        basket: this.currentBasket
      })
    }
    else {
      this.$router.push({
        name: Routes.ResidentStoreBasket,
        params: {
          resident_id: this.resident_id
        }
      })
    }
    this.updateAmounts()
  }
}
</script>

<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';

#resident-store-shipping {

  .shipping-title {
    margin: 0px 0px 26px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #2C2C2C;
  }

  .delivery-header {
    display: flex;
    align-items: center;
    margin: 0px 10px 22px 0px;

    @media (max-width: map-get($breakpoints, sm)) {
        margin: 0px 10px 22px 10px;
    }

    .delivery-icon {
      width: 42px;
      height: 46px;
    }

    .delivery-content {
      margin: 0px 0px 0px 18px;

      .delivery-title {
        font-weight: 600;
        color: #2C2C2C;
      }
      .delivery-legend {

      }
    }
  }

  .delivery-cost {
    .item-label {
      font-size: 14px !important;
      margin: -20px 0 0 0 !important;
    }
    .item-price {
      font-size: 14px !important;
      margin: -20px 0 0 0 !important;
    }
  }

  .shipping-detail {
    margin: 0px 0px 72px 0px;
    border: 2px solid #D8D8D8;
    border-radius: 8px;
    font-family: Montserrat;
    color: #2C2C2C;

    .detail-header {
      display: flex;
      align-items: center;
      padding: 22px 22px 22px 22px;

      .header-title {
        flex-grow: 1;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
      .header-price {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: right;

        @media (max-width: map-get($breakpoints, sm)) {
            margin: 0px 0px 0px 20px;
            min-width: 110px;
        }

        .next-order-amount {
          font-weight: normal;
          font-size: 12px;
        }
      }
    }

    .detail-content:last-child {
      margin: 10px 0px 0px 0px;
    }

    .detail-content {
      margin: 0px 0px 37px 0px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
      border-radius: 8px;
      background: white;

      .order-category {
        padding: 10px 22px 10px 22px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }

      .order-items {

        .order-item {
          display: flex;
          border-top: 1px solid #D8D8D8;

          @media (min-width: map-get($breakpoints, sm)) {
            padding: 22px 22px 22px 22px;
          }
          @media (max-width: map-get($breakpoints, sm)) {
            padding: 9px 10px 9px 10px;
          }

          .item-informations-delivery {
            text-align: center;
            margin: 5px auto;
          }

          .item-photo {

            .item-image {
              width: 92px;
              height: auto;
            }
          }

          .item-informations {
            flex-grow: 1;
            margin: 0px 0px 0px 18px;

            .item-title {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
            }

            .item-description {
              margin: 4px 0px 0px 0px;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 14px;
            }

            .item-legend {
              margin: 4px 0px 0px 0px;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 14px;
            }

            .item-frequency {
              margin: 8px 0px 0px 0px;
              font-weight: bold;
              font-size: 14px;
              line-height: 14px;
            }

            .frequency-select {
              margin: 9px 0px 0px 0px;

              @media (min-width: map-get($breakpoints, sm)) {
                width: 202px;
              }
              @media (max-width: map-get($breakpoints, sm)) {
                width: 150px;
              }
            }
          }

          .item-actions {
            display: flex;
            align-items: center;

            @media (max-width: map-get($breakpoints, sm)) {
              flex-wrap: wrap;
            }

            .quantity-select {
              width: 200px;
              margin: 0px 0px 30px 0px;
              border: 1px solid rgba(var(--vs-primary), 1);
              border-radius: 5px;
            }

            .item-price {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 14px;
              text-align: right;
            }

            .item-frequency {
              margin: 8px 0px 0px 0px;
              font-weight: bold;
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }

      .order-vendor {
        display: flex;
        align-items: center;
        padding: 10px 22px 10px 22px;

        .vendor-name {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }

        .vendor-informations {
          margin: 0px 0px 0px 10px;

          a {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-decoration: underline;
            color: rgba(var(--vs-primary), 1);
          }
        }
      }

      .order-items {

        .order-item {
          border-top: none;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #D8D8D8;

          @media (min-width: map-get($breakpoints, sm)) {
            padding: 9px 22px 9px 22px;
          }
          @media (max-width: map-get($breakpoints, sm)) {
            padding: 9px 10px 9px 10px;
          }

          .item-title {
            margin: 0px 10px 0px 0px;
            flex-grow: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
          }
          .item-quantity {
            padding: 4px 20px 4px 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            background: #BDBDBD;
            border-radius: 3px;
            text-align: center;
            color: white;
          }

          .item-price {
            width: 120px;
            margin: 0px 0px 0px 20px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 14px;
            text-align: right;
          }
        }
      }

      .shipping-label {
        margin: 9px 0px 0px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
      }

      .shipping-selector {

      }

      .shipping-custom {
        padding: 10px 0px 10px 0px;
        border-bottom: 1px solid #D8D8D8;

        .label {
          max-width: 100%;
          margin: 0px auto 0px auto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
        }
      }

      .shipping-fees {
        display: flex;
        align-items: center;
        padding: 22px 22px 22px 22px;

        .fees-title {
          flex-grow: 1;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
        }

        .fees-price {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 14px;
          text-align: right;
        }
      }
    }
  }

  @media (max-width: map-get($breakpoints, sm)) {
    .page-row {
        flex-wrap: wrap;
    }
    .left-col {
        flex-grow: 1;
    }
    .right-col {
        flex-grow: 1;

        .basket-summary {
            position: relative;
        }
    }
  }
  @media (min-width: map-get($breakpoints, sm)) {
    .page-row {
        flex-wrap: nowrap;
    }
    .left-col {
        flex-grow: 1;
    }
    .right-col {
        flex-shrink: 0;
        width: 380px;

        .basket-summary {
            position: fixed;
            width: 350px;
        }
    }
  }
  @media (min-width: map-get($breakpoints, md)) {
    .page-row {
        flex-wrap: nowrap;
    }
    .left-col {
        flex-grow: 1;
    }
    .right-col {
        flex-shrink: 0;
        flex-basis: 480px;

        .basket-summary {
            position: fixed;
            width: 450px;
        }
    }
  }
}
</style>
