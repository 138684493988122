<template>
  <div class="shipping-mode-selector">
    <div class="common-options">
      <div
          v-if="isStandardOptionEnabled()"
          :class="['option', isModeSelected('standard') ? 'option-selected' : '', isModeDisabled('standard') ? 'option-disabled' : '', isFreeOptionEnabled() ? '' : 'mb-8']"
          @click="onModeBtnClick('standard')"
          >
        <div class="option-header">
          <!-- <div class="option-circle"></div> -->
          <div class="option-title">
            <div class="flex-container">
              <div class="title-primary">Standard</div>
              <div class="shipping">{{
                  getModePropertyById('standard', 'totalShippingPrice')
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="option-legend">
          Livraison entre <strong>{{ getDeliveryDelayPropertyById('standard', 'earliestDays') }} et
          {{ getDeliveryDelayPropertyById('standard', 'latestDays') }} jours</strong>
        </div>
      </div>
      <div
          v-if="isExpressOptionEnabled()"
          :class="['option', isModeSelected('express') ? 'option-selected' : '', isModeDisabled('express') ? 'option-disabled' : '']"
          @click="onModeBtnClick('express')"
         >
        <div class="option-header">
          <div class="option-title">
            <div class="flex-container">
              <div class="title-primary">Express</div>
              <div class="shipping">{{
                  getModePropertyById('express', 'totalShippingPrice')
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="option-legend">
          Livraison entre <strong>{{ getDeliveryDelayPropertyById('express', 'earliestDays') }} et
          {{ getDeliveryDelayPropertyById('express', 'latestDays') }} jours</strong>
        </div>
      </div>
      <div
          v-if="isWishedDateOptionEnabled()"
          :class="['option', isModeSelected('wished-date') ? 'option-selected' : '', isModeDisabled('wished-date') ? 'option-disabled' : '']"
          >
        <div class="option-header"  @click="onModeBtnClick('wished-date')">
          <div class="option-title">
            <div class="flex-container">
              <div class="title-primary">Choisissez une date</div>
              <div class="shipping">{{
                  getModePropertyById('wished-date', 'totalShippingPrice')
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="option-legend">
          <datepicker v-model="selectedDate" :disabled-dates="disabledDate" :value="dateDefault" :language="fr" @closed="onModeBtnClick('wished-date')"></datepicker>
      </div>
      </div>
      <div v-if="isFreeOptionEnabled()"
           :class="['option', isModeSelected('ehpad') ? 'option-selected' : '', isModeDisabled('ehpad') ? 'option-disabled' : '']"
           @click="onModeBtnClick('ehpad')"
           >
        <div class="option-header">
          <div class="option-title">
            <div class="flex-container">
              <div class="title-primary">Le mois prochain</div>
              <div class="shipping">Gratuit</div>
            </div>
          </div>
        </div>
        <div class="option-legend">Livraison entre le <strong>5 et le 15 {{ nextMonth }}</strong></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNextMonth} from '@/modules/date'
import moment from 'moment'
import Formats from '@/constants/formats'
import Datepicker from 'vuejs-datepicker'
import {fr} from 'vuejs-datepicker/dist/locale'

export default {
  name: 'ShippingModeSelector',
  components: {
    Datepicker
  },
  data() {
    return {
      disabledDate: {to:null, from: null},
      dateDefault: null,
      fr: fr,
      selectedDate: null,
      loader: false
    }
  },
  props: [
    'modes',
    'selected',
    'disabled',
    'loading',
    'data',
    'date'
  ],
  computed: {
    nextMonth() {
      return getNextMonth()
    },
  },
  watch: {
    date: {
      handler: function () { this.disabledDatePicker() },
      deep: true
    }
  },
  mounted() {
    this.disabledDatePicker()
  },
  methods: {
    isStandardOptionEnabled() {
      return this.getModeById('standard')
    },
    isExpressOptionEnabled() {
      return this.getModeById('express')
    },
    isWishedDateOptionEnabled() {
      return this.getModeById('wished-date')
    },
    disabledDatePicker() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      let currentDate = new Date();
      let currentDateSelectable = new Date();
      let begin = this.getDeliveryDelayPropertyById('wished-date', 'earliestDays')
      let end = this.getDeliveryDelayPropertyById('wished-date', 'latestDays')
      currentDate.setHours(23)
      currentDate.setDate(currentDate.getDate() + begin ).toLocaleString('fr-FR', options);
      currentDateSelectable.setDate(currentDate.getDate()).toLocaleString('fr-FR', options);
      let dateMax = new Date();
      dateMax.setDate(currentDate.getDate() + end ).toLocaleString('fr-FR', options);
      this.disabledDate = {
          to: currentDateSelectable,
          from: dateMax,
      }
      if (this.date !== null) {
        this.selectedDate = moment(this.date).utc().set({hour:21,minute:59,second:59,millisecond:59}).format()
        this.dateDefault = moment(this.date).utc().set({hour:21,minute:59,second:59,millisecond:59}).format()

      }
      else {
        this.selectedDate = moment(currentDate).utc().set({hour:21,minute:59,second:59,millisecond:59}).format()
        this.dateDefault = moment(currentDate).utc().set({hour:21,minute:59,second:59,millisecond:59}).format()
      }
      if (this.selectedDate === null) {
        this.selectedDate =  moment(currentDate).utc().set({hour:21,minute:59,second:59,millisecond:59}).format()
      }
    },
    isFreeOptionEnabled() {
      return this.getModeById('ehpad') && (this.getModePropertyById('ehpad', 'totalShippingPrice') == "Gratuit")
    },
    isModeSelected(id) {
      if (this.selected.code === id) {
        return true
      }
      else {
        return false
      }
    },
    isModeDisabled(id) {
      if (this.disabled) {
        return true
      }
      const mode = this.getModeById(id)
      if (mode && mode.disabled) {
        return true
      }
      return false
    },
    getModeById(id) {
      return this.modes.find((mode) => {
        return mode.code === id
      })
    },
    getModePropertyById(id, name) {
      const mode = this.getModeById(id)
      if (!mode || mode[name] === 0) {
        return 'Gratuit'
      }
      return '' + mode[name] + '€' || null
    },
    getDeliveryDelayPropertyById(id, name) {
      const mode = this.getModeById(id)
      if (!mode) {
        return null
      }
      return mode['deliveryTime'][name] || null
    },
    getDateInXDays(nbDays) {
      moment.locale('fr')
      return moment().add('d',nbDays).format(Formats.DisplayDateText)
    },
    onModeBtnClick(id) {
      const mode = this.getModeById(id)
      if (!this.disabled && mode && !mode.disabled && (this.selected.code !== mode.code || mode.code === 'wished-date' ) && !this.loading) {
        this.loader = true
        let date = null

        if (mode.code === 'wished-date') {
          date = moment.utc(this.selectedDate).set({hour:21,minute:59,second:59,millisecond:59}).format()
          this.selectedDate = date
        }
        this.$emit('change', id, this.data, date)
        this.loader = false
      }
      return null
    }
  }
}
</script>

<style lang="scss">
$bp-mobile: 768px;
$bp-tablet: 768px;
$bp-desktop: 992px;

.shipping-mode-selector {

  .common-options {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0px auto 0px auto;

    @media (min-width: $bp-tablet) {
      padding: 24px;
    }
    @media (max-width: $bp-mobile) {
      flex-wrap: wrap;
      padding: 22px 8px 22px 8px;
    }
  }

  // .separator {
  //   display: flex;
  //   align-items: center;

  //   .separator-bar {
  //     flex-grow: 1;
  //     border-top: 1px solid #D8D8D8;
  //   }

  //   .separator-label {
  //     width: 48px;
  //     font-style: normal;
  //     font-weight: 600;
  //     font-size: 14px;
  //     line-height: 18px;
  //     text-align: center;
  //   }
  // }

  // .free-options {
  //   display: flex;
  //   align-items: center;
  //   max-width: 568px;
  //   margin: 24px auto 0px auto;
  //   padding: 0px 0px 24px 0px;
  // }

  .option {
    flex-basis: 230px;
    flex-grow: 1;
    max-width: 300px;
    padding: 12px 12px 12px 12px;
    border: 2px solid rgba(var(--vs-primary), .43);
    border-radius: 8px;
    cursor: pointer;

    @media (min-width: $bp-tablet) {
      margin: 0px 20px 0px 0px;
    }
    @media (max-width: $bp-mobile) {
      margin: 0px 0px 20px 0px;
    }

    &.option-selected {
      .flex-container {
        .shipping {
          color: #fff !important;
        }
      }
    }

    .option-header {
      display: flex;
      align-items: center;

      .option-circle {
        width: 18px;
        height: 18px;
        margin: 0px 10px 0px 0px;
        border-radius: 20px;
        border: 1px solid rgba(var(--vs-primary), 1);
      }

      .option-title {
        width: 100%;

        .flex-container {
          display: flex;
          justify-content: space-between;

          .shipping {
            font-weight: bold;
            color: rgba(var(--vs-primary), 1);
            text-align: right;
          }

        }

        .title-primary {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-align: left;
          color: rgba(var(--vs-primary), 1);
        }

        .title-explain {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          text-align: left;
          color: rgba(var(--vs-primary), 1);
        }
      }
    }

    .option-legend {
      margin: 10px 0px 0px 0px;
      text-align: center;
    }
  }

  .option:last-child {
    margin: 0px 0px 0px 0px;
  }

  .option-wide {

    .option-header {
      justify-content: center;
    }
  }

  .option-selected {
    background: rgba(var(--vs-primary), 1);

    .option-header {

      .option-circle {
        border: 2px solid white;
      }

      .option-title {

        .title-primary {
          color: white;
        }

        .title-explain {
          color: white;
        }
      }
    }
  }

  .option-disabled {
    border: 2px solid #D8D8D8;
    background: rgba(189, 189, 189, 0.2);
    cursor: default;

    .option-header {

      .option-circle {
        border: 2px solid #BDBDBD;
      }

      .option-title {

        .title-primary {
          color: #BDBDBD;
        }

        .title-explain {
          color: #BDBDBD;
        }
      }
    }

    .option-legend {
      color: #BDBDBD;
    }
  }

  .vdp-datepicker input {
    width: 125px;
    margin: 0 auto;
    text-align: center;
    display: block;
    cursor: pointer;
  }
}
</style>
